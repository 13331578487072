@font-face {
	font-family: "opensans";
	src: url("/static/font/opensans/open-sans-v15-latin-regular.woff2") format("woff2"),
		url("/static/font/opensans/open-sans-v15-latin-regular.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "opensans-bold";
	src: url("/static/font/opensans/open-sans-v15-latin-700.woff2") format("woff2"),
		url("/static/font/opensans/open-sans-v15-latin-700.woff") format("woff");
	font-display: swap;
}
