@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.auth-layer-marketing-text {
	justify-content: flex-end;
	display: flex;
	align-items: center;
	height: 100%;
	margin-right: 40px;

	.home-marketing {
		max-width: 350px;
		width: 100%;

		img {
			width: 100%; // svg to display
		}
	}
}
