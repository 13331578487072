@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.technical-error {
	&__intro {
		@extend %text-bold;

		text-align: center;
		max-width: 540px;
		margin: 0 auto 20px;
	}

	.alert-message {
		margin-bottom: 20px;
	}
}
