@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
@import "../../.styles/button";

.button--facebook {
	@extend %button--facebook;

	// Pour l'affichage du logo facebook
	display: flex;
	align-items: center;
	justify-content: center;

	&.button--disabled {
		background: #4c69ba !important;
		border: 1px solid #4c69ba !important;
		color: white !important;
		cursor: not-allowed !important;
	}
}
