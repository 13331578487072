@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$header-height-desktop: 72px !default;
$header-height-mobile: 64px !default;
$header-background-color: white !default;
$header-text-color: $black !default;
$header-box-shadow: unset !default;

.header {
	$this: &;

	display: flex;
	align-items: center;
	padding: 0 16px;
	height: $header-height-mobile;
	background: $header-background-color;
	box-shadow: $header-box-shadow;
	border-bottom: $header-border-bottom;
	position: relative;
	z-index: 5;

	&--sticky {
		position: sticky;
		top: -1px;
		z-index: 3;
	}

	@include gridle_state(md) {
		padding: 0 48px;
		height: $header-height-desktop;
		z-index: 4; // to be above sdp filters
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 auto;
	}

	&__back {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		@include gridle_state(md) {
			margin-right: 31px;
		}

		svg {
			color: $header-text-color;
		}
	}

	&__credit {
		margin-left: 20px;
		margin-right: 24px;

		@include gridle_state(md) {
			margin-left: 35px;
			margin-right: 35px;
		}

		.relative-link__text {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;

			svg {
				height: 27px;
				width: 27px;
				color: $header-text-color;

				@include gridle_state(md) {
					height: 30px;
					width: 30px;
				}
			}

			.amount {
				@extend %text-bold;

				margin-top: 8px;
				color: $header-text-color;
				font-size: 1rem;
				line-height: 1.3rem;
				text-transform: uppercase;
			}
		}

		&-label {
			@extend %text-bold;

			margin-top: 8px;
			font-size: 1rem;
			line-height: 1.3rem;
			text-transform: uppercase;
			color: $header-text-color;
		}
	}

	.account-menu {
		position: relative; // sinon le libelle du picto ne s'affiche pas
	}

	&__phone {
		svg {
			color: $header-text-color;
		}
	}

	&__operator {
		position: fixed;
		top: 15px;
		right: 40px;
		color: $black;
		padding: 5px 10px;
		border: 1px solid $grey-medium;
		background: $grey-light;
		border-radius: $border-radius-small;
		z-index: 10;
	}

	&__smart-dp-form {
		width: 100%;
		max-width: 900px;

		.sdp-search-form {
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			border-radius: $border-radius-big;
			overflow: hidden;
			height: 45px;

			&__form {
				@include gridle_state(md) {
					height: 100%;
				}
			}

			&__field {
				height: 100%;

				.advanced-select__select {
					height: 100%;

					.advanced-select__select {
						&__value-container,
						&__control {
							height: 100%;
						}
					}
				}
			}

			&__footer {
				height: 45px;
				width: 65px;
				flex: none;

				& button {
					border-radius: unset;
					height: 45px;
					min-height: 45px;
				}
			}

			.advanced-select__select__input-container,
			.advanced-select__select__single-value {
				margin-top: 15px;
			}

			.date-calendar-input__input,
			.travellers-room-button__input {
				margin-top: 15px;
			}

			.date-calendar-input {
				&__close_icon {
					top: 13px;
				}
			}

			.advanced-select__label,
			.date-calendar-input__label,
			.travellers-room-button__label {
				top: 15px;
			}

			.date-calendar-input--touched .date-calendar-input__label,
			.advanced-select--touched .advanced-select__label,
			.travellers-room-button--touched .travellers-room-button__label {
				top: 5px;
			}

			&__error {
				@include gridle_state(md) {
					position: absolute;
					display: flex;
					justify-content: center;
					width: 1024px;

					&::before {
						border-bottom: 10px solid #ffe5e5;
						border-right: 10px solid transparent;
						border-left: 10px solid transparent;
						top: 10px;
						content: "";
						display: block;
						position: absolute;
					}
				}
			}
		}
	}

	&__logo {
		display: flex;
		align-items: center;

		.logo-brand__logo {
			height: $header-height-mobile;

			@include gridle_state(md) {
				height: $header-height-desktop;
			}
		}

		// Custom logo EK
		&--eh {
			align-items: normal;
			height: 100%;
			margin-left: 0;
			z-index: 3;

			.logo-brand {
				height: 98px;
				width: 70px;

				@include gridle_state(md) {
					height: 132px; // header height + filter height
					width: 100px;
					transform: unset;
				}
			}

			.relative-link,
			.relative-link__text {
				display: block;
			}

			&-small {
				.logo-brand {
					height: 86px;
					transform: translateY(-22px);
				}
			}
		}
	}

	&--transparent {
		background: transparent;
		box-shadow: none;
		border: none;

		.header__credit {
			svg,
			.amount,
			&-label {
				color: white;
			}
		}

		.hamburger-account {
			svg,
			&__label {
				color: white;
			}
		}
	}
}
