@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$header-height: 70px; // pris de /app/pages/Header L8
$text-shadow-mobile: 0 2px 20px rgba(0, 0, 0, 0.3);
$auth-forgotten-password-margin-bottom: 25px;
$auth-marketing-message-shadow: none !default;
$auth-form-text-color-static: $black !default;
$auth-form-background-static: white !default;

.auth-layer-mobile {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: scroll;
	z-index: 10;
	background: white;

	.register-message {
		padding: 0 20px 20px;

		&__body {
			margin: 0 0 20px;
		}
	}

	&__wrapper {
		position: relative;

		&::before {
			content: "";
			background-image: $auth-marketing-message-shadow;
			position: absolute;
			height: 200px;
			width: 100vw;
			z-index: 2;
		}
	}

	footer,
	.home-header {
		position: relative;
		z-index: 2;
	}

	.background-progressive-image {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}

	.home-header {
		border: none;

		i.icon:first-of-type {
			border: 1px solid white;
			border-radius: 50%;
		}
	}

	.auth-layer {
		margin: 0 auto; // centrage du form sur tablette
		position: relative;
		z-index: 2;

		.auth__main {
			padding: 30px;
			background: transparent;
		}

		&__main {
			padding: 0 20px 40px;
			width: 100%;
			margin: 0 auto;
			box-sizing: border-box;

			@include gridle_state(sm) {
				max-width: 450px;
			}
		}

		&__marketing-text {
			position: relative;
			margin: 20px 0;
			z-index: 2;
		}
	}

	.shop-switcher__button {
		color: white;
	}

	&__certifications {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		width: 100%;
		height: 50px;
		background-image: linear-gradient(to bottom, rgba(64, 64, 64, 0), rgba(28, 40, 53, 0.85));
		text-align: center;
		padding-bottom: 10px;
		z-index: 1;

		svg {
			fill: white;
			stroke: white;
			z-index: 1;

			&:first-of-type {
				border-right: 1px solid white;
				padding-right: 10px;
				margin-right: 10px;
			}
		}
	}

	input {
		&:-webkit-autofill {
			-webkit-text-fill-color: $auth-form-text-color-static;
			-webkit-box-shadow: 0 0 0 1000px rgba($auth-form-background-static, 0.3) inset;
			transition: background-color 5000s ease-in-out 0s;
		}
	}

	&__rtb {
		.reason-to-book-block {
			padding: 32px 16px;

			@include gridle_state(md) {
				max-width: 1024px;
				margin: 0 auto;
				padding: 40px 0;
			}
		}
	}
}
